import React, { useEffect, useState } from "react";
import * as Icon from "react-feather";
import { useSelector, useDispatch } from "react-redux";
import { Nav, NavLink, NavItem, Collapse } from "reactstrap";
import { useLocation, Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import HorizontalSidebarData from "../sidebardata/HorizontalSidebarData";
import NavItemContainer from "./NavItemContainer";
import NavSubMenu from "./NavSubMenu";
import {
  getProfileDetails,
  userDeleteInitial,
  userDeleteFailed,
  // getProfilePlanDetails,
} from "../../../slices/retailer/UserAppSlice/UserDetailsSlice";
import { fetchData } from "../../../slices/authSlice/dataSlice";
// import { fetchUserControlData } from '../../../slices/authSlice/getControlSlice';
import Logo from "../../../assets/images/logos/logo.png";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const getActive = document.getElementsByClassName("activeLink");
  const [collapsed2, setCollapsed2] = React.useState(false);
  const currentURL = location.pathname.split("/").slice(0, -1).join("/");
  const { user: currentUser } = useSelector((state) => state.user);
  const { token: Usertoken } = useSelector((state) => state.user);
  const userDeleteResponse = useSelector(
    (state) => state.userdetails.userDeleteSucces
  );
  const [, setProData] = useState([]);
  const activeBg = useSelector((state) => state.customizer.sidebarBg);
  const isFixed = useSelector((state) => state.customizer.isSidebarFixed);
  useEffect(() => {
    dispatch(fetchData({ Usertoken, currentUser }));
  }, [dispatch]);

  const setting = [
    {
      title: "Change Password",
      href: "/Agent/ChangePassword",
      icon: <Icon.ChevronRight />,
      id: 12.1,
      collapisble: false,
    },
    {
      title: "Set TPIN",
      href: "/Agent/SetTPIN",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Reset TPIN",
      href: "/Agent/ResetTPIN",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
    {
      title: "Raise Complaint",
      href: "/Agent/ComplaintListAgent",
      icon: <Icon.ChevronRight />,
      id: 12.2,
      collapisble: false,
    },
  ];

  const toggle2 = () => {
    setCollapsed2(!collapsed2);
  };

  useEffect(() => {
    if (userDeleteResponse === true) {
      dispatch(getProfileDetails({ currentUser, Usertoken }))
        .unwrap()
        .then((res) => {
          if (res.status === true) {
            setProData(res.data[0].ProfilePic);
            dispatch(userDeleteInitial());
          } else {
            dispatch(userDeleteFailed(res));
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [userDeleteResponse]);
  useEffect(() => {
    dispatch(getProfileDetails({ currentUser, Usertoken }))
      .unwrap()
      .then((res) => {
        if (res.status === "true") {
          dispatch(userDeleteInitial());
          setProData(res.data[0].ProfilePic);
        } else {
          dispatch(userDeleteFailed(res));
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (userDeleteResponse === true) {
      dispatch(getProfileDetails({ currentUser, Usertoken }))
        .unwrap()
        .then((res) => {
          if (res.status === "true") {
            dispatch(userDeleteInitial());
            setProData(res.data);
          } else {
            dispatch(userDeleteFailed(res));
            setLoading(false);
          }
        })
        .catch(() => {});
    }
  }, [userDeleteResponse]);

  return (
    <div
      className={`sidebarBox shadow bg-white ${isFixed ? "fixedSidebar" : ""}`}
    >
      <SimpleBar style={{ height: "100%" }}>
        <div className="py-3 px-4 d-flex align-items-center d-none ">
          {/* <img src={logo} alt="user" width="30" className="rounded-circle" /> */}
          <div>
            <img src={Logo} alt="user" width="170" />
          </div>
          <div className="ms-3 opacity-75 text-truncate user-name"></div>
        </div>
        <div className="mb-4 pb-5 verticalSidebar flex-wrap" loading={loading}>
          <Nav vertical className={activeBg === "white" ? "" : "lightText"}>
            {currentUser.role === "6" && currentUser.kycStatus ? (
              <>
                {currentUser.status === "1" ? (
                  <>
                    {currentUser.password_Reset === false ? (
                      <NavItem>
                        <NavLink
                          tag={Link}
                          to="/Dashboards/RetailerDashboard"
                          className="gap-2"
                        >
                          <span className="sidebarIcon d-flex align-items-center">
                            <Icon.Home />
                          </span>
                          <span className="hide-mini w-100">
                            <div className="d-flex align-items-center">
                              <span>Dashboard</span>
                            </div>
                          </span>
                        </NavLink>
                      </NavItem>
                    ) : null}
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/SelfBank/RetailorBankList"
                        className="gap-2"
                      >
                        <span className="sidebarIcon d-flex align-items-center">
                          {" "}
                          <Icon.Send />
                        </span>
                        <span className="hide-mini w-100">
                          <div className="d-flex align-items-center">
                            <span>Self Bank </span>
                          </div>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/Agent/BalanceRequestHistory"
                        className="gap-2"
                      >
                        <span className="sidebarIcon d-flex align-items-center">
                          <Icon.MousePointer />
                        </span>
                        <span className="hide-mini w-100">
                          <div className="d-flex align-items-center">
                            <span>Balance Request</span>
                          </div>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        tag={Link}
                        to="/Reports/AllRetailorReports"
                        className="gap-2"
                      >
                        <span className="sidebarIcon d-flex align-items-center">
                          <Icon.List />
                        </span>
                        <span className="hide-mini w-100">
                          <div className="d-flex align-items-center">
                            <span>Agent Reports</span>
                          </div>
                        </span>
                      </NavLink>
                    </NavItem>
                    <NavItem
                      className={collapsed2 && getActive ? "activeParent" : ""}
                      loading={loading}
                    >
                      <NavLink
                        className="cursor-pointer  gap-2"
                        onClick={toggle2}
                      >
                        <span className="sidebarIcon d-flex align-items-center">
                          <Icon.Settings />
                        </span>
                        <span className="hide-mini w-100 ">
                          <div className="d-flex align-items-center ">
                            <span className="d-block">Setting</span>
                            <span className="ms-auto">
                              <i
                                className={`bi  fs-8 ${
                                  collapsed2
                                    ? "bi-chevron-down"
                                    : "bi-chevron-right"
                                }`}
                              />
                            </span>
                          </div>
                        </span>
                      </NavLink>
                      <Collapse
                        isOpen={collapsed2}
                        navbar
                        tag="ul"
                        className="subMenu"
                      >
                        {setting.map((item) => (
                          <NavItem
                            key={item.title}
                            className={`hide-mini ${
                              location.pathname === item.href
                                ? "activeLink"
                                : ""
                            }`}
                          >
                            <NavLink
                              tag={Link}
                              to={item.href}
                              className="gap-2"
                            >
                              <span className="sidebarIcon">{item.icon}</span>
                              <span className="hide-mini">
                                <span>{item.title}</span>
                              </span>
                            </NavLink>
                          </NavItem>
                        ))}
                      </Collapse>
                    </NavItem>
                  </>
                ) : null}
              </>
            ) : currentUser.role === "5" && currentUser.kycStatus ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Dashboards/DistributorDashboard"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Home />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/ViewRetailerDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UserPlus />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Add Agent</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/ManageCreditDebitDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Manage Credit/Debit</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/CreditDetailsDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>

                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Credit Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/LedgerReport" className="gap-2">
                   */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/LedgerReportDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Distributor Ledger</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/CommissionReport" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/CommisionReportDistributor"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Commission Report</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Distributor/ChangePasswordDistributor" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/Distributor/ChangePasswordDistributer"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Settings />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Change Password</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Bank/DistributorBankList" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/Distributor/DistributorBankList"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UploadCloud />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Self Bank Master</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Distributor/BalanceRequestHistoryDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink tag={Link} to="/Funds/FundRequestDistributor" className="gap-2">
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem> */}

                <NavItem>
                  {/* <NavLink tag={Link} to="/Distributor/TransactionReportAgent" className="gap-2"> */}
                  <NavLink tag={Link} to="#" className="gap-2 d-none">
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Agent Trans Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
              </>
            ) : currentUser.role === "4" && currentUser.kycStatus ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Dashboards/SuperDistributorDashboard"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Home />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/SuperViewRetailerDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UserPlus />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Add Distributor</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/SuperManageCreditDebitDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Manage Credit/Debit</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/SuperCreditDetailsDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Credit Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/SPLedgerReports" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/LedgerSuperReportDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Ledger Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/SPCommissionReport" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/CommisionSuperReportDistributor"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Commission Report</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/SuperDistributor/ChangePasswordSP" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/SuperDistributor/ChangePasswordSP"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Settings />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Change Password</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Bank/SPDistributorBankList" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/SuperDistributor/SPDistributorBankList"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UploadCloud />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Self Bank Master</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Funds/FundRequestSuperDistributor" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/SuperDistributor/BalanceRequestHistorySuper"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
              </>
            ) : currentUser.role === "3" && currentUser.kycStatus ? (
              <>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/Dashboards/MasterDistributorDashboard"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Home />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Dashboard</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/MasterViewSuperDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UserPlus />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Add Super Distributor</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/dashboards/MasterManageCreditDebitDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Manage Credit/Debit</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink
                    tag={Link}
                    to="/MasterDistributor/MasterCreditDetails"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Clipboard />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Credit Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/SPLedgerReports" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/LedgerMasterReportDistributor"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Ledger Details</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Reports/SPCommissionReport" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/dashboards/CommisionSuperReportDistributor"
                    className="gap-2 d-none"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.List />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Commission Report</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/SuperDistributor/ChangePasswordSP" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/MasterDistributor/ChangePasswordMd"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Settings />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Change Password</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  {/* <NavLink tag={Link} to="/Bank/SPDistributorBankList" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/MasterDistributor/MasterBankList"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.UploadCloud />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Self Bank Master</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>

                <NavItem>
                  {/* <NavLink tag={Link} to="/Funds/FundRequestSuperDistributor" className="gap-2"> */}
                  <NavLink
                    tag={Link}
                    to="/MasterDistributor/BalanceRequestHistoryMaster"
                    className="gap-2"
                  >
                    <span className="sidebarIcon d-flex align-items-center">
                      <Icon.Send />
                    </span>
                    <span className="hide-mini w-100">
                      <div className="d-flex align-items-center">
                        <span>Balance Request</span>
                      </div>
                    </span>
                  </NavLink>
                </NavItem>
              </>
            ) : currentUser.role == "1" || currentUser.role === "2" ? (
              <>
                {HorizontalSidebarData.map((navi) => {
                  if (navi.caption) {
                    return (
                      <div
                        className="navCaption text-uppercase mt-4"
                        key={navi.caption}
                      >
                        {navi.caption}
                      </div>
                    );
                  }
                  if (navi.children) {
                    return (
                      <NavSubMenu
                        key={navi.id}
                        list={HorizontalSidebarData.length}
                        icon={navi.icon}
                        title={navi.title}
                        items={navi.children}
                        suffix={navi.suffix}
                        suffixColor={navi.suffixColor}
                        // toggle={() => toggle(navi.id)}
                        // collapsed={collapsed === navi.id}
                        isUrl={currentURL === navi.href}
                      />
                    );
                  }
                  return (
                    <NavItemContainer
                      key={navi.id}
                      //toggle={() => toggle(navi.id)}
                      className={
                        location.pathname === navi.href ? "activeLink" : ""
                      }
                      to={navi.href}
                      title={navi.title}
                      suffix={navi.suffix}
                      suffixColor={navi.suffixColor}
                      icon={navi.icon}
                    />
                  );
                })}
              </>
            ) : (
              <h3 className="text-info" style={{ marginLeft: "1.7rem" }}>
                KYC Pending
              </h3>
            )}
          </Nav>
        </div>
      </SimpleBar>
    </div>
  );
};

export default Sidebar;
